import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import RenderBlock from '../utils/render-block'

import SEO from '../components/seo'

class PageTemplate extends Component {

  render() {
    let {
      data: { page },
    } = this.props

    let metaDescription = page.yoast_head_json?.description

    return (
      <>
        <SEO
          title={he.decode(page.yoast_title)}
          bodyClass={page.slug}
          description={metaDescription}
        />
        { page.acf.content_blocks_page && page.acf.content_blocks_page.map((el, i) => {
          return RenderBlock(el.__typename, el, i)
        }) }
      </>
    )
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    page: wordpressPage(id: { eq: $id }) {
      id
      title
      slug
      yoast_title
      yoast_meta {
       name
       content
       property
      }
      yoast_head_json {
        description
      }
      acf {
        content_blocks_page {
          __typename
          ... on WordPressAcf_basic_content {
            content
          }
          ... on WordPressAcf_people {
            introduction
            image {
              alt_text
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            team {
              name
              bio
            }
          }
          ... on WordPressAcf_title_columns {
            title
            columns {
              content
            }
          }
          ... on WordPressAcf_subscribe {
            title
          }
          ... on WordPressAcf_video {
            video
            video_poster {
              alt_text
              source_url
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_projects {
            featured_project {
              post_title
              post_name
              acf {
                project_type
                featured_image {
                  alt_text
                  source_url
                  localFile {
                    childImageSharp {
                      original {
                        src
                      }
                    }
                  }
                }
              }
            }
            projects {
              post_title
              post_name
              acf {
                project_type
                featured_image {
                  alt_text
                  source_url
                  localFile {
                    childImageSharp {
                      original {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
